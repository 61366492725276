import classnames from "classnames";
import hark from "hark";
import PropTypes from "prop-types";
import React from "react";
import Spinner from "react-spinner";
import Logger from "../../../../Logger";
import * as appPropTypes from "./appPropTypes";
//import CachedIcon from '@material-ui/icons/Cached';

const logger = new Logger("PeerView");

const TIMEOUTMS = 10;
export default class PeerView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      audioVolume: 0,
      audioShareVolume: 0,
      showInfo: window.SHOW_INFO || false,
      videoResolutionWidth: null,
      videoResolutionHeight: null,
      videoCanPlay: false,
      videoElemPaused: false,
      maxSpatialLayer: null,
      autoPlayAudioLoad: false,
      autoPlayAudioShareLoad: false,
      autoPlayVideoLoad: false,
    };

    // Latest received video track.
    // @type {MediaStreamTrack}
    this._audioTrack = null;
    this._audioShareTrack = null;
    // Latest received video track.
    // @type {MediaStreamTrack}
    this._videoTrack = null;

    // Hark instance.
    // @type {Object}
    this._hark = null;

    // Periodic timer for reading video resolution.
    this._videoResolutionPeriodicTimer = null;
  }

  render() {
    const {
      isMe,
      peer,
      audioProducerId,
      videoProducerId,
      audioConsumerId,
      audioShareConsumerId,
      videoConsumerId,
      videoRtpParameters,
      consumerSpatialLayers,
      consumerTemporalLayers,
      consumerCurrentSpatialLayer,
      consumerCurrentTemporalLayer,
      consumerPreferredSpatialLayer,
      consumerPreferredTemporalLayer,
      consumerPriority,
      audioMuted,
      videoVisible,
      videoMultiLayer,
      audioCodec,
      audioShareCodec,
      videoCodec,
      audioScore,
      audioShareScore,
      videoScore,
      onChangeDisplayName,
      onChangeMaxSendingSpatialLayer,
      onChangeVideoPreferredLayers,
      onChangeVideoPriority,
      onRequestKeyFrame,
      onStatsClick,
      hideVideo,
      shareScreen,
      onRotate,
    } = this.props;
    const { audioTrack, audioShareTrack, videoTrack } = this.props;
    const {
      audioVolume,
      showInfo,
      videoResolutionWidth,
      videoResolutionHeight,
      videoCanPlay,
      videoElemPaused,
      maxSpatialLayer,
    } = this.state;
    return (
      <div
        data-component="PeerView"
        className={classnames({
          hiddenVideo: !videoVisible || !videoCanPlay,
        })}
      >
        <video
          ref="videoElem"
          className={classnames({
            "is-me": isMe && !shareScreen,
            hidden: !videoVisible || !videoCanPlay || hideVideo,
            "network-error":
              videoVisible &&
              videoMultiLayer &&
              consumerCurrentSpatialLayer === null,
            to90: onRotate === 1,
            to180: onRotate === 2,
            to270: onRotate === 3,
          })}
          playsInline
          muted
          controls={false}
          onPlaying={() => {
            // alert("The video is now playing 1");
          }}
        />
        <audio ref="audioElem" muted={isMe || audioMuted} controls={false} />
        <audio ref="audioShareElem" controls={false} />
        {/* <Tooltip 
					title={translate("TEXT_UPDATE")} 
					placement="right"
					style={{
						position:"absolute",
						left: "6px",
						top: "6px",
					}}
				>
					<Fab 
						size="small"
						className="actions-icons"
						onClick={() => {
							this._setTracks(audioTrack, videoTrack, audioShareTrack, true);
						}}
					>
						<IconComponent
							size={16}
						>
							<CachedIcon className="icon-white"></CachedIcon>
						</IconComponent>
					</Fab>
				</Tooltip> */}
        <canvas
          ref="canvas"
          className={classnames("face-detection", { "is-me": isMe })}
        />

        <div className="volume-container">
          <div className={classnames("bar", `level${audioVolume}`)} />
        </div>

        {videoVisible && videoScore < 5 ? (
          <div className="spinner-container">
            <Spinner />
          </div>
        ) : null}

        {videoElemPaused ? <div className="video-elem-paused" /> : null}
      </div>
    );
  }
  autoPlayAudioShare = () => {
    const { audioShareTrack } = this.props;
    const { audioShareElem } = this.refs;
    if (audioShareElem && audioShareTrack) {
      audioShareElem
        .play()
        .catch((error) =>
          logger.warn("audioShareElem.play() failed:%o", error)
        );
    }
    this.setState({
      autoPlayAudioShareLoad: false,
    });
  };
  autoPlayAudio = () => {
    const { audioTrack, audioShareTrack, videoTrack } = this.props;
    const { audioElem, videoElem } = this.refs;
    if (audioElem && audioTrack) {
      audioElem
        .play()
        .catch((error) => logger.warn("audioElem.play() failed:%o", error));
    }
    this.setState({
      autoPlayAudioLoad: false,
    });
  };
  autoPlayVideo = () => {
    const { audioTrack, videoTrack } = this.props;
    const { audioElem, videoElem } = this.refs;
    if (videoTrack && videoElem) {
      videoElem
        .play()
        .catch((error) => logger.warn("audioElem.play() failed:%o", error));
    }
    this.setState({
      autoPlayVideoLoad: false,
    });
  };
  componentDidMount() {
    const { audioTrack, audioShareTrack, videoTrack } = this.props;

    this._setTracks(audioTrack, videoTrack, audioShareTrack);
  }

  componentWillUnmount() {
    if (this._hark) this._hark.stop();

    clearInterval(this._videoResolutionPeriodicTimer);

    const { videoElem } = this.refs;

    if (videoElem) {
      videoElem.oncanplay = null;
      videoElem.onplay = null;
      videoElem.onpause = null;
    }
  }

  componentWillUpdate() {
    const {
      isMe,
      audioTrack,
      audioShareTrack,
      videoTrack,
      videoRtpParameters,
    } = this.props;

    const { maxSpatialLayer } = this.state;

    if (isMe && videoRtpParameters && maxSpatialLayer === null) {
      this.setState({
        maxSpatialLayer: videoRtpParameters.encodings.length - 1,
      });
    } else if (isMe && !videoRtpParameters && maxSpatialLayer !== null) {
      this.setState({ maxSpatialLayer: null });
    }

    this._setTracks(audioTrack, videoTrack, audioShareTrack);
  }

  _setTracks(audioTrack, videoTrack, audioShareTrack, force) {
    if (!force) {
      if (
        this._audioTrack === audioTrack &&
        this._videoTrack === videoTrack &&
        this._audioShareTrack === audioShareTrack
      )
        return;
    }

    this._audioTrack = audioTrack;
    this._videoTrack = videoTrack;
    this._audioShareTrack = audioShareTrack;
    if (this._hark) this._hark.stop();

    this._stopVideoResolution();

    const { audioElem, audioShareElem, videoElem } = this.refs;

    if (audioTrack) {
      const stream = new MediaStream();
      stream.addTrack(audioTrack);

      audioElem.srcObject = stream;
      // audioElem.play()
      // 	.catch((error) => logger.warn('audioElem.play() failed:%o', error));
      setTimeout(() => {
        this.autoPlayAudio();
      }, TIMEOUTMS);
      this._runHark(stream);
    } else {
      audioElem.srcObject = null;
    }

    if (audioShareTrack) {
      const stream = new MediaStream();

      stream.addTrack(audioShareTrack);
      audioShareElem.srcObject = stream;
      // audioElem.play()
      // 	.catch((error) => logger.warn('audioElem.play() failed:%o', error));
      setTimeout(() => {
        this.autoPlayAudioShare();
      }, TIMEOUTMS);
      this._runHark(stream);
    } else {
      audioShareElem.srcObject = null;
    }

    if (videoTrack) {
      const stream = new MediaStream();

      stream.addTrack(videoTrack);
      videoElem.srcObject = stream;
      videoElem.oncanplay = () => this.setState({ videoCanPlay: true });

      videoElem.onplay = () => {
        this.setState({ videoElemPaused: false });

        audioElem
          .play()
          .catch((error) => logger.warn("audioElem.play() failed:%o", error));
      };

      videoElem.onpause = () => this.setState({ videoElemPaused: true });

      setTimeout(() => {
        this.autoPlayVideo();
      }, TIMEOUTMS);
      // videoElem.play()
      // 	.catch((error) => logger.warn('videoElem.play() failed:%o', error));

      this._startVideoResolution();
    } else {
      videoElem.srcObject = null;
    }
  }

  _runHark(stream) {
    if (!stream.getAudioTracks()[0])
      throw new Error("_runHark() | given stream has no audio track");

    this._hark = hark(stream, { play: false });

    // eslint-disable-next-line no-unused-vars
    this._hark.on("volume_change", (dBs, threshold) => {
      // The exact formula to convert from dBs (-100..0) to linear (0..1) is:
      //   Math.pow(10, dBs / 20)
      // However it does not produce a visually useful output, so let exagerate
      // it a bit. Also, let convert it from 0..1 to 0..10 and avoid value 1 to
      // minimize component renderings.
      let audioVolume = Math.round(Math.pow(10, dBs / 85) * 10);

      let audioShareVolume = Math.round(Math.pow(10, dBs / 85) * 10);

      if (audioVolume === 1) audioVolume = 0;
      if (audioVolume !== this.state.audioVolume)
        this.setState({ audioVolume });

      if (audioShareVolume === 1) audioShareVolume = 0;
      if (audioShareVolume !== this.state.audioShareVolume)
        this.setState({ audioShareVolume });
    });
  }
  //_startVideoResolution: this function is used to get the video resolution
  //of the video track, and update the state of the component. So that the
  //component can be re-rendered with the new resolution.
  _startVideoResolution() {
    this._videoResolutionPeriodicTimer = setInterval(() => {
      const { videoResolutionWidth, videoResolutionHeight } = this.state;
      const { videoElem } = this.refs;

      if (
        videoElem.videoWidth !== videoResolutionWidth ||
        videoElem.videoHeight !== videoResolutionHeight
      ) {
        this.setState({
          videoResolutionWidth: videoElem.videoWidth,
          videoResolutionHeight: videoElem.videoHeight,
        });
      }
    }, 500);
  }

  _stopVideoResolution() {
    clearInterval(this._videoResolutionPeriodicTimer);

    this.setState({
      videoResolutionWidth: null,
      videoResolutionHeight: null,
    });
  }

  _printProducerScore(id, score) {
    const scores = Array.isArray(score) ? score : [score];

    return (
      <React.Fragment key={id}>
        <p>streams:</p>

        {scores
          .sort((a, b) => {
            if (a.rid) return a.rid > b.rid ? 1 : -1;
            else return a.ssrc > b.ssrc ? 1 : -1;
          })
          .map(
            (
              { ssrc, rid, score },
              idx // eslint-disable-line no-shadow
            ) => (
              <p key={idx} className="indent">
                {rid !== undefined ? (
                  <React.Fragment>
                    {`rid:${rid}, ssrc:${ssrc}, score:${score}`}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {`ssrc:${ssrc}, score:${score}`}
                  </React.Fragment>
                )}
              </p>
            )
          )}
      </React.Fragment>
    );
  }

  _printConsumerScore(id, score) {
    return (
      <p key={id}>
        {`score:${score.score}, producerScore:${score.producerScore}`}
      </p>
    );
  }
}

PeerView.propTypes = {
  isMe: PropTypes.bool,
  peer: PropTypes.oneOfType([appPropTypes.Me, appPropTypes.Peer]).isRequired,
  audioProducerId: PropTypes.string,
  videoProducerId: PropTypes.string,
  audioConsumerId: PropTypes.string,
  audioShareConsumerId: PropTypes.string,
  videoConsumerId: PropTypes.string,
  audioRtpParameters: PropTypes.object,
  audioShareRtpParameters: PropTypes.object,
  videoRtpParameters: PropTypes.object,
  consumerSpatialLayers: PropTypes.number,
  consumerTemporalLayers: PropTypes.number,
  consumerCurrentSpatialLayer: PropTypes.number,
  consumerCurrentTemporalLayer: PropTypes.number,
  consumerPreferredSpatialLayer: PropTypes.number,
  consumerPreferredTemporalLayer: PropTypes.number,
  consumerPriority: PropTypes.number,
  audioTrack: PropTypes.any,
  audioShareTrack: PropTypes.any,
  videoTrack: PropTypes.any,
  audioMuted: PropTypes.bool,
  videoVisible: PropTypes.bool.isRequired,
  videoMultiLayer: PropTypes.bool,
  audioCodec: PropTypes.string,
  audioShareCodec: PropTypes.string,
  videoCodec: PropTypes.string,
  audioScore: PropTypes.any,
  audioShareScore: PropTypes.any,
  videoScore: PropTypes.any,
  onChangeDisplayName: PropTypes.func,
  onChangeMaxSendingSpatialLayer: PropTypes.func,
  onChangeVideoPreferredLayers: PropTypes.func,
  onChangeVideoPriority: PropTypes.func,
  onRequestKeyFrame: PropTypes.func,
  onStatsClick: PropTypes.func.isRequired,
  onRotate: PropTypes.any,
};
