
import AssignmentIcon from "@material-ui/icons/Assignment";
import GroupIcon from "@material-ui/icons/Group";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
// import ButtonMicrophone from "./../../modules/components/room/buttons/customs/ButtonMicrophone";
import translate from "app/lang/translate";
import ListItem from "app/modules/components/menu/ListItem";
import RoomContext from "app/modules/meeting/RoomContext";
import {
  toggleProduct,
  toogleAdminPanel,
  toogleContacts
} from "app/redux/actions/roomActions";
import PropTypes from "prop-types";
import React,{ useEffect, useMemo, useState } from "react";
import * as roomActions from "app/redux/actions/roomActions";
// import IconButton from "@material-ui/core/IconButton";
import Inforoom from "app/modules/meeting/components/Inforoom.jsx";
import ButtonMenuTop from "app/modules/meeting/components/ButtonMenuTop.jsx";
import StatusBar from "app/modules/meeting/components/Room/roomTypes/event/desktop/StatusBar.jsx";
//import Button from "./Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ButtonToggleMenu from "app/modules/meeting/components/ButtonToggleMenu";
import MenuTopSpacer from "app/modules/meeting/components/MenuTopSpacer";
import { useCallback, useContext } from "react";
import { useLocation } from "react-router-dom";
import Infospeaker from "app/modules/meeting/components/Infospeaker.jsx";
import useRoomStatus from "app/modules/meeting/components/Room/hooks/useRoomStatus.jsx";
const MenuTopSupport = ({ extraData, onToggleHideMe, canShareScreen }) => {
  const [openPopoverSettings, setOpenPopoverSettings] = useState(false);
  const [showInfoRoom, setShowInfoRoom] = useState(false);
  const [showInfoSpeaker, setShowInfoSpeaker] = useState(false);
  const [setWebcamState] = useState(null);
  const [canSendWebcam, setCanSendWebcam] = useState(null);
  const roomClient = useContext(RoomContext);

  const [blinkNotificationColor, setBlinkNotificationColor] =
    useState("inherit");
  const [cantBlinkNotification, setCantBlinkNotification] = useState(false);
  const {
    me,
    dispatch,
    enabledMic,
    enabledCam,
    audioProducer,
    videoProducer,
    videoProducerShare,
    room,
  } = useRoomStatus();

  const toogleContactsMenu = () => {
    dispatch(toogleContacts());
  };

  const toogleAdminPanelMenu = () => {
    dispatch(toogleAdminPanel());
  };

  useEffect(() => {
    if (canSendWebcam && !me.canSendWebcam) {
      if (!me.canSendWebcam) setWebcamState(null);
      else if (videoProducerShare) setWebcamState(true);
      else setWebcamState(false);
      setCanSendWebcam(me.canSendWebcam);
    }
  });

  const handleCloseSettings = () => {
    setOpenPopoverSettings(false);
  };

  const handleShowInfoRoom = () => {
    handleCloseSettings();
    setShowInfoRoom(!showInfoRoom);
  };

  const handleShowInfoSpeaker = () => {
    handleCloseSettings();
    setShowInfoSpeaker(!showInfoSpeaker);
  };

  const handleDesktopShare = () => {
    if (videoProducerShare) {
      roomClient.disableShare();
    } else {
      roomClient.enableShare();
    }
  };

  const toggleProductPanel = () => {
    dispatch(toggleProduct());
  };
  const handleCloseRoom = () => {
    dispatch(roomActions.openModal("closeRoom", {}));
  };
  const MicrophoneIcon = useMemo(
    () => () => {
      if (enabledMic) {
        return <MicIcon />;
      } else if (!enabledMic) {
        return <MicOffIcon />;
      } else {
        return null;
      }
    },
    [enabledMic]
  );
  const CameraIcon = useMemo(
    () => () => {
      if (enabledCam) {
        return <VideocamIcon />;
      } else if (!enabledCam) {
        return <VideocamOffIcon />;
      } else {
        return null;
      }
    },
    [enabledCam]
  );
  const [hideMe, setHideMe] = useState(false);
  const EyeIcon = useMemo(() => {
    return hideMe ? <VisibilityOffIcon /> : <VisibilityIcon />;
  }, [hideMe]);

  const handleHideMe = useCallback(() => {
    onToggleHideMe(!hideMe);
    setHideMe(!hideMe);
  }, [hideMe, onToggleHideMe]);

  const { search } = useLocation();
  const { avaibleHideMe } = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    return {
      avaibleHideMe: searchParams.get("avaibleHideMe"),
    };
  }, [search]);
  return (
    <div data-component="MenuTopSupport">
      <div className={"botoneraLeft"}>
        <StatusBar showDescriptions={false} showPersonIcon={false} />
      </div>
      <div className={"botoneraRight"}>
        <ButtonToggleMenu
          buttons={[
            extraData && (
              <ListItem
                onClick={toggleProductPanel}
                icon={<AssignmentIcon />}
                text={translate(`SHOW_PRODUCT_INFO`)}
              />
            ),
            <ListItem
              onClick={toogleContactsMenu}
              icon={<GroupIcon />}
              text={translate(`SHOW_PARTICIPANTS_LIST`)}
            />,

            <ListItem
              onClick={toogleAdminPanelMenu}
              icon={<VpnKeyIcon />}
              text={translate("TOOLTIP_SHOW_ADMIN")}
            />,
            avaibleHideMe && (
              <ListItem
                onClick={handleHideMe}
                icon={EyeIcon}
                text={translate(`BUTTON_HIDE_ME`)}
              />
            ),
          ]}
        ></ButtonToggleMenu>

        <MenuTopSpacer></MenuTopSpacer>
        <ButtonMenuTop
          tip="TOOLTIP_ENABLED_CAMERA"
          child={<CameraIcon />}
          actionClick={() => {
            if (
              me.canSendWebcam &&
              videoProducer &&
              videoProducer.type !== "share"
            ) {
              roomClient.disableWebcam();
            } else {
              roomClient.enableWebcam();
            }
          }}
          tipClose="TOOLTIP_DISABLED_CAMERA"
          toggle={enabledCam}
        ></ButtonMenuTop>
        <ButtonMenuTop
          tip="TOOLTIP_ENABLED_MICROPHONE"
          child={<MicrophoneIcon />}
          actionClick={() => {
            if (!me.canSendMic) roomClient.unmuteMic();
            else if (!audioProducer) roomClient.unmuteMic();
            else if (!audioProducer.paused) roomClient.muteMic();
            else roomClient.unmuteMic();
          }}
          toggle={enabledMic}
          tipClose="TOOLTIP_DISABLED_MICROPHONE"
        ></ButtonMenuTop>
        {canShareScreen && (
          <ButtonMenuTop
            child={<ScreenShareIcon />}
            tip="SHARE_MY_SCREEN_ON"
            actionClick={handleDesktopShare}
            toggle={room.show_shareScreen}
            tipClose="SHARE_MY_SCREEN_OFF"
          ></ButtonMenuTop>
        )}

        <div className="detail-bottom"></div>
      </div>
      <Inforoom open={showInfoRoom} handleClose={handleShowInfoRoom} />
      <Infospeaker open={showInfoSpeaker} handleClose={handleShowInfoSpeaker} />
    </div>
  );
};
MenuTopSupport.propTypes = {
  extraData: PropTypes.shape({
    elements: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        props: PropTypes.object,
      }).isRequired
    ).isRequired,
  }),
  canShareScreen: PropTypes.bool,
  onToggleHideMe: PropTypes.func,
};

export default MenuTopSupport;
