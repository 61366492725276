import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import translate from "../../../lang/translate";
import { Button, Grid } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import InputSelect from "../../components/forms/InputSelect";
import CountrySelect from "../../components/decorators/CountrySelect";
import * as yup from "yup";
import configForm from "../../forms/configForm";
import _ from "lodash";

const schema = yup.object().shape({
  language: yup.string().required(),
});

let InterpreterForm = (props) => {
  const room = useSelector((store) => store.room);
  const [langs, setLangs] = useState([]);
  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    getValues,
    setValue,
    formState,
  } = useForm(
    configForm.defaultConfigForm({
      validationSchema: schema,
    })
  );
  const { isSubmitting, isValid } = formState;
  const selectedLanguage = watch("language");

  const onSubmit = (data) => {
    try {
      props.onFinishSubmit(data);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    try {
      const langsSelect = _.get(room, "info.languages", []).map((lang) => ({
        ...lang,
        id: lang.isoCode,
      }));
      setLangs(langsSelect);
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  }, [_.get(room, "info.languages", [])]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        as={InputSelect}
        name={`language`}
        control={control}
        options={langs}
        errors={errors}
        cancel={false}
        labelComponent={CountrySelect}
      />
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        className={"mb-20"}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onSubmit({ isInvited: true });
          }}
        >
          {translate("TEXT_INTERPRETER_GOTO_PARTICIPANTE")}
        </Button>
      </Grid>
      <div className={"flex flex-end flex-middle"}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isSubmitting || !selectedLanguage}
        >
          {translate("TEXT_LOGIN")}
        </Button>
      </div>
    </form>
  );
};

export default InterpreterForm;
