import _ from "lodash";
import React, { useEffect, useState, useCallback, useMemo } from "react";
//import classnames from "classnames";
import { changeLang } from "../../../../../../../redux/stateActions";
//import ButtonMenuTop from "../ButtonMenuTop";
import { PEERS_TYPES } from "app/constants";
import StatusBar from "../../event/desktop/StatusBar";
//import StatusBar from "../Room/roomTypes/event/desktop/StatusBar";
import ButtonCamera from "./ButtonCamera";
import ButtonMicrophone from "./ButtonMicrophone";
import ButtonShareScreen from "./ButtonShareScreen";
import useRoomStatus from "../../../hooks/useRoomStatus";
import ButtonHideMe from "./ButtonHideMe";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
const MenuTopCustomer = ({ onToggleHideMe, canShareScreen }) => {
  const [setWebcamState] = useState(null);
  const [canSendWebcam, setCanSendWebcam] = useState(null);
  const { me, dispatch, videoProducerShare, room, peers, enabledLangs } =
    useRoomStatus();
  const interpreter = useMemo(
    () => peers.find((p) => p.peerType === PEERS_TYPES.INTERPRETER),
    [peers.length]
  );
  useEffect(() => {
    if (canSendWebcam && !me.canSendWebcam) {
      if (!me.canSendWebcam) setWebcamState(null);
      else if (videoProducerShare) setWebcamState(true);
      else setWebcamState(false);
      setCanSendWebcam(me.canSendWebcam);
    }
  });
  const store = useSelector((store) => store);
  const { search } = useLocation();
  const { avaibleHideMe } = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    return {
      avaibleHideMe: searchParams.get("avaibleHideMe"),
    };
  }, [search]);

  const toggleLang = useCallback(() => {
    if (interpreter) {
      if (
        _.get(interpreter, "peerLang") &&
        me.peerLang !== interpreter.peerLang
      ) {
        dispatch(changeLang(interpreter.peerLang));
      }
      if (
        _.get(room, "info.officialLang") &&
        me.peerLang !== room.info.officialLang
      ) {
        dispatch(changeLang(room.info.officialLang));
      }
    }
  }, [me.peerLang, interpreter, room, dispatch]);

  return (
    <div data-component="MenuTopCustomer">
      <div className={"botoneraLeft"}>
        <StatusBar showDescriptions={false} showPersonIcon={false} />
      </div>
      <div className={"botoneraRight"}>
        
        <ButtonCamera></ButtonCamera>
        <ButtonMicrophone></ButtonMicrophone>
        {canShareScreen && <ButtonShareScreen></ButtonShareScreen>}
      </div>
    </div>
  );
};

MenuTopCustomer.propTypes = {
  onToggleHideMe: PropTypes.func,
  canShareScreen: PropTypes.bool,
};

export default MenuTopCustomer;
