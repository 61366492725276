import helper from "../../../utils/helpers"
const initialState = {
	messages: [],
	enabled: false,
	newMessages: 0
};

const chat = (state = initialState, action) => {
	let newMessages = 0;
	switch (action.type) {
		case 'ADD_MESSAGE': {
			newMessages = 0;
			if (!state.enabled){
				newMessages= state.newMessages+1;
			}
			const message = {
				...action.payload,
				time: helper.getNowTime()
			}
			return { ...state, messages: [...state.messages, message], newMessages: newMessages };
		}
		case 'TOGGLE_CHAT': {
			let enabled = !state.enabled
			newMessages = !enabled?0:newMessages;
			return { ...state, enabled, newMessages };
		}

		default:
			return state;
	}
};

export default chat;
