import React from "react";
import translate from "../../../lang/translate";
import { Tooltip } from "@material-ui/core";
const InputDate = ({ title, children, className={}, ...props }) => {
  return (
    <Tooltip title={translate(title)} fontSize="large" className={className} {...props}>
      {children}
    </Tooltip>
  );
};

export default InputDate;
