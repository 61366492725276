import { useMemo } from "react";
import DefaultRoom from "./DefaultRoom.jsx";
import RoomCustomer from "./roomTypes/customer/RoomCustomer.jsx";
import RoomSupport from "./roomTypes/support/RoomSupport.jsx";
import RoomInterpreter from "./roomTypes/interpreter/RoomInterpreter.jsx";
import RoomHubeetCustomer from "./roomTypes/hubeetCustomer/RoomHubeetCustomer.jsx";
import { ROOM_VIEWS } from "../../../../constants/index.js";
import RoomHubeetInterpreter from "./roomTypes/hubeetInterpreter/RoomHubeetInterpreter.jsx";
import RoomHubeetSupport from "./roomTypes/hubeetSupport/RoomHubeetSupport.jsx";
import RoomHubeetInvited from "./roomTypes/hubeetInvited/RoomHubeetCustomer.jsx";
import { isMobile } from "react-device-detect";
import RoomHubeetOwner from "./roomTypes/hubeet/RoomHubeetOwner/RoomHubeetOwner.jsx";
const useRoom = ({ view, hubeet }) => {
  const ComponentRoom = useMemo(() => {
    switch (view) {
      case ROOM_VIEWS.CUSTOMER:
        if (hubeet) return RoomHubeetCustomer
        return RoomCustomer;
      case ROOM_VIEWS.SUPPORT:
        if (hubeet && isMobile) return RoomHubeetSupport;
        if (hubeet ) return RoomHubeetOwner;
        return RoomSupport;
      case ROOM_VIEWS.INTERPRETER:
        if (hubeet) return RoomHubeetInterpreter;
        return RoomInterpreter;
      case ROOM_VIEWS.INVITED:
        if (hubeet) return RoomHubeetInvited;
        return DefaultRoom;
      default:
        return DefaultRoom;
    }
  }, [view]);

  return {
    ComponentRoom,
  };
};
export default useRoom;
