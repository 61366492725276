//import React, { useEffect, useRef } from "react";
import React, { useCallback, useEffect, useState } from 'react'
//import { useDispatch, useSelector } from "react-redux";
import RoomContext from 'app/modules/meeting/RoomContext'
import AdminPanel from 'app/modules/meeting/components/AdminPanel.jsx'
import Contacts from 'app/modules/meeting/components/Contacts.jsx'
import useApi from 'app/modules/meeting/components/Room/hooks/useApi'
import useRoomStatus from 'app/modules/meeting/components/Room/hooks/useRoomStatus.jsx'
import { Appear } from 'app/modules/meeting/components/transitions.jsx'
import classnames from 'classnames'
import _ from 'lodash'
import { useContext } from 'react'
import { isMobile } from 'react-device-detect'
import MenuTopSupport from './MenuTopSupport'
import ProductPanel from './ProductPanel'
import RoomWrapperContent from './RoomWrapper'

const RoomHubeetSupportContent = ({ enableAsking }) => {
  const { extraData, setAsking } = useApi()
  const {
    room,
    
    me,

  } = useRoomStatus()
  const roomClient = useContext(RoomContext)


  const showAdminPanel = room.show_adminPanel
  const showContact = room.show_contacts
  const showProduct = room.show_productInfo
  const showChat = room.show_chat

  const [hideMe, setHideMe] = useState(false)
  const handleHideMe = useCallback(
    hide => {
      setHideMe(hide)
    },
    [setHideMe]
  )
  

  const [isMove, setIsMove] = useState(false)
  let timeout
  useEffect(() => {})
  const handleMove = e => {
    if (isMove) {
      clearTimeout(timeout)
      timeout = setTimeout(function () {
        setIsMove(false)
      }, 4000)
    } else {
      setIsMove(true)
    }
  }
  useEffect(() => {
    if (room.state === 'connected') {
      if (enableAsking === true && me && !me.asking) {
        setAsking(me.id)
        roomClient.enableWebcam()
        roomClient.unmuteMic()
      }
    }
  }, [room.state])
  
  return (
    <Appear duration={300}>
      <div data-component={'RoomContent'} onMouseMove={handleMove}>
        <MenuTopSupport
          extraData={extraData}
          onToggleHideMe={handleHideMe}
          canShareScreen={!isMobile}
        />
        <div className='wrapper'>
          <RoomWrapperContent enableAsking={enableAsking} hideMe={hideMe} />
          <div
            className={classnames('panel', {
              hidden:
                !showContact && !showChat && !showAdminPanel && !showProduct
            })}
          >
            {showContact && <Contacts open={true} />}
            {_.get(extraData, 'elements', false) && showProduct && (
              <ProductPanel extraData={extraData} open={true}></ProductPanel>
            )}
            <div className={!showAdminPanel ? 'hidden' : ''}>
              <AdminPanel open={true} />
            </div>
          </div>
        </div>
      </div>
    </Appear>
  )
}

export default RoomHubeetSupportContent
