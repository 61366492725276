import React from "react";
import Text from "./../Text/Text"
import translate from "../../../lang/translate";
const CountrySelect = ({ id,...props }) => {
    return (
        <div className={`w-100`} data-component="CountrySelect">
            <object height={15} width={15} data={`/room/images/flags-iso/flat/16/${id.toUpperCase()}.png`}  type="image/png">
                <img src={`/room/images/flags-iso/flat/16/defualt.png`} />
            </object>

            {/*<Text uuid={`LANG_${id}`}/>*/}            
            { props.name }
        </div>
    );
};


export default CountrySelect;
