import React, { useEffect, useMemo, useState } from 'react'

import RoomContext from 'app/modules/meeting/RoomContext'
import AdminPanel from 'app/modules/meeting/components/AdminPanel.jsx'
import Contacts from 'app/modules/meeting/components/Contacts.jsx'
import Presentation from 'app/modules/meeting/components/Presentation.jsx'
import useApi from 'app/modules/meeting/components/Room/hooks/useApi'
import useRoomStatus from 'app/modules/meeting/components/Room/hooks/useRoomStatus.jsx'
import classnames from 'classnames'
import _ from 'lodash'
import { useContext } from 'react'
import Scrollbar from 'react-perfect-scrollbar'
import ColumnListParticipants from '../../../../Views/ColumnListParticipants/ColumnListParticipants'
import ProductPanel from './ProductPanel'
import WaitingState from 'app/modules/components/generic/WaitingState'

const RoomWrapperContent = ({ enableAsking, hideMe }) => {
  const { extraData, setAsking } = useApi()
  const { room, peers } = useRoomStatus()

  const demo = false
  const showPresentation = _.get(room, 'presentation.video')

  const { lateralPeers, gridPeers } = useMemo(() => {
    const peersWithoutMe = peers.filter(p => !p.isMe)
    const me = peers.find(p => p.isMe)
    let lp = []
    let gp = []
    if (showPresentation) {
      lp = [me, ...peersWithoutMe]
    } else {
      gp = [...peersWithoutMe]
      lp = [me]
    }
    return {
      lateralPeers: lp,
      gridPeers: gp
    }
  }, [showPresentation, peers])

  const showLateral = useMemo(() => {
    return lateralPeers?.length > 0
  }, [lateralPeers.length])

  return (
    <div
      className={classnames('container-super-grid2', {
        withLateral: showLateral
      })}
    >
      <div>
        {showPresentation && (
          <div className={classnames('super-presentation')}>
            <Presentation />
          </div>
        )}
        {!showPresentation && gridPeers?.length === 0 && (
          <div className={classnames('super-empty')}>
            <WaitingState isMobile={false} />
          </div>
        )}
        {!showPresentation && gridPeers?.length > 0 && (
          <ColumnListParticipants
            peers={gridPeers}
            allPeers={peers}
            mode={'grid'}
            demo={demo}
            transformlabel={false}
            classes={'hiddenScrollX'}
          />
        )}
      </div>
      {showLateral && (
        <div>
          <Scrollbar className='content'>
            <ColumnListParticipants
              peers={lateralPeers}
              allPeers={peers}
              mode={'column'}
              demo={demo}
              transformlabel={false}
              classes={'hiddenScrollX'}
            />
          </Scrollbar>
        </div>
      )}
    </div>
  )
}

export default RoomWrapperContent
