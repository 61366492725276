import React, { useContext, useEffect } from "react";

import RoomContext from "app/modules/meeting/RoomContext";

import RoomContent from "./components/RoomContent";
import DisabledRoomModal from "app/modules/meeting/components/DisabledRoomModal";
import ModalForUserCloseRoom from "app/modules/meeting/components/ModalForUserCloseRoom";
import ModalForUserEject from "app/modules/meeting/components/ModalForUserEject";
import ModalForUserMultipleDevice from "app/modules/meeting/components/ModalForUserMultipleDevice";
import ModalForUserNotInvited from "app/modules/meeting/components/ModalForUserNotInvited";
// import Invite from './Invite';
// import Speeker from './Speeker';
//import Menu from './Menu';
import Notifications from "app/modules/meeting/components/Notifications";
import { Appear } from "app/modules/meeting/components/transitions";
import ModalCloseRoomOnlineSupport from "app/modules/meeting/components/ModalCloseRoomOnlineSupport";

function RoomHubeetOwner({ enableAsking }) {
  const roomClient = useContext(RoomContext);
  useEffect(() => {
    roomClient.join();
  }, []);
  return (
    <div id="app-room-hubeet-support-container">
      <Appear duration={300}>
        <div data-component="Room">
          <Notifications />
          <RoomContent enableAsking={enableAsking} />
          <DisabledRoomModal></DisabledRoomModal>
          <ModalCloseRoomOnlineSupport />
          <ModalForUserCloseRoom />
          <ModalForUserEject />
          <ModalForUserMultipleDevice />
          <ModalForUserNotInvited />
        </div>
      </Appear>
    </div>
  );
}

export default RoomHubeetOwner;
